
import fieldMixin from "@/mixins/field"

type Options = Record<string, any>

export default fieldMixin.extend({
  props: {
    options: { type: Object as VuePropType<Options>, required: true },
    isEmptyAllowed: { type: Boolean, default: false },
  },
})
