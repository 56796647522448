
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import debounce from "lodash/debounce"
import groupBy from "lodash/groupBy"
// import type { Tag } from "@/types/api/tag"

type Filters = Record<string, string>

export default Vue.extend({
  props: {
    total: { type: Number, default: null },
    filters: { type: Object as VuePropType<Filters>, default: () => ({}) },
    noYear: { type: Boolean, default: false },
  },

  data () {
    return {
      visible: false,
      model: this.filters,
      regions: [] as string[],
    }
  },

  computed: {
    ...mapState("app", ["tags"]),

    resetVisible (): boolean {
      return Object.values(this.filters).some(Boolean)
    },

    tagsOptionsByGroup (): Record<string, Record<string, any>> {
      const tagsByGroup = groupBy(this.tags, "group.alias")

      return Object.fromEntries(
        Object.entries(tagsByGroup).map(([groupAlias, tags]) => {
          const opts: Record<string, any> = {}
          tags.forEach((t) => {
            opts[this.$fmt.localize(t.title)] = t.id
          })
          return [groupAlias, opts]
        }),
      )
    },

    regionOpts (): Record<string, string> {
      return Object.fromEntries(
        this.regions.map(r => [r, r]),
      )
    },
  },

  watch: {
    model: {
      handler: debounce(function (this: any, model) {
        for (const k in model) if (!model[k]) delete model[k]
        this.$emit("update:filters", model)
      }, 300),
      deep: true,
    },
  },

  async mounted () {
    await Promise.all([
      this.fetchTags(),
      this.fetchRegions(),
    ])
  },

  methods: {
    ...mapActions("app", { fetchTags: "FETCH_TAGS" }),

    reset  () {
      this.model = {}
      this.$emit("reset")
    },

    apply () {
      this.$emit("update:filters", this.model)
      this.visible = false
    },

    async fetchRegions () {
      const [res, err] = await this.$api.nominants.listNominantRegion()
      if (err) console.error(err)
      this.regions = res || []
    },
  },
})
