// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4SQ7SyzZ{position:relative}.jDfG-EBf{-webkit-appearance:none;-moz-appearance:none;appearance:none;cursor:pointer;padding-right:calc(var(--spacing-sm)*2 + .5rem)}.\\-25FKYxe{color:var(--color-bg-invert);height:.25rem;pointer-events:none;position:absolute;right:var(--spacing-sm);top:50%;transform:translateY(-50%);width:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "_4SQ7SyzZ",
	"select": "jDfG-EBf",
	"arrow": "-25FKYxe"
};
module.exports = ___CSS_LOADER_EXPORT___;
