
import fieldWrapperMixin from "@/mixins/field-wrapper"

export default fieldWrapperMixin.extend({
  props: {
    isDisabled: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    ignoreLabelClick: { type: Boolean, default: false },
  },

  computed: {
    parsedHelp (): string {
      const { help } = this
      if (!help) return ""
      return this.$fmt.localize(help)
    },
  },
})
